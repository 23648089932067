<template lang="pug">
div.view_container
  Header
  div.view_title Privacy Policy
  div.privacy(v-html="privacy_linebreak")
</template>

<script>
import Header from '../components/Header'

export default {
  name: 'Privacy',
  data () {
    return {
      privacy: `

WishWell ("WishWell", “we,” “us,” or “our”) has created this privacy statement (“Privacy Statement”) in order to demonstrate our commitment to you, our user, (“You” or “Your”) regarding Your privacy with respect to information that You submit to WishWell by or through the App or Services (defined below), whether You are a “Visitor” (which means that you may participate in the App (defined below)) or a “Member” of WishWell (which means you have either registered with WishWell and established an account). Visitors and Members are individually referred to as a “User,” and collectively, as “Users.”

Privacy on WishWell (the "App"") is of great importance to us. Because we gather important information from our Users of the App and related services provided by WishWell (the “Services”), we have established this Privacy Statement as a means to communicate our information collection, use and dissemination practices. In order to access and use the App and/or Services, you must agree to our Terms of Service and any other Applicable terms. Capitalized terms not defined in this Privacy Statement will have the meaning ascribed to them in the Terms of Service.

The focus of our App and Service is to let You and Your friends record and share a collection of videos to send to someone.

So we are clear on the terminology we use, “Personal Information” means contact information (e.g., your name, address, email address, and/or telephone number) and any other non-public information that is used or intended to be used to personally identify an individual, and any other non-public information that is associated with that information. “Anonymous Information” means information that is not used or intended to be used to personally identify an individual.

BY USING OUR APP OR SERVICES OR SUBMITTING PERSONAL INFORMATION THROUGH OUR APP OR SERVICES, YOU AGREE TO THE TERMS OF THIS PRIVACY STATEMENT AND YOU EXPRESSLY CONSENT TO THE PROCESSING OF YOUR PERSONAL INFORMATION ACCORDING TO THIS PRIVACY STATEMENT. YOUR PERSONAL INFORMATION MAY BE PROCESSED BY US IN THE COUNTRY WHERE IT WAS COLLECTED AS WELL AS OTHER COUNTRIES (INCLUDING THE UNITED STATES) WHERE LAWS REGARDING PROCESSING OF PERSONAL INFORMATION MAY BE LESS STRINGENT THAN THE LAWS IN YOUR COUNTRY.

COLLECTED INFORMATION

In general, the Personal Information we gather enables us to provide the Services to you and helps us learn more about the use of the App and/or Services. We collect Personal Information that you submit to us voluntarily at registration and in the course of using the App and/or Services, including, but not exclusive to, when you are logged in on the App. To use the Services you must create an “Account” by providing your name, email address, a password, and potentially additional information. You may connect to the App or Services through Facebook or Google, in which case we may collect that information from Facebook or Google for the purposes of creating your Account. When you communicate with us by sending us an email, we will collect and store any information that is contained in your communications with us.

We also collect and store the Personal Information of your friends that you provide us for the purposes of sending an invitation or any notifications on the group video you would like your friends to participate in.

Anonymous Information

As you use the App or Services, certain Anonymous Information may be passively collected and stored, such as your Internet protocol address, domain names, browser type, and access times. We may also use Cookies (as defined below) or web bugs and navigational data like Uniform Resource Locators (URLs) to gather information regarding the date and time of your visit and the solutions and information for which You searched and viewed, or on which advertisements displayed on the App You have viewed and/or clicked. “Cookies” are small pieces of information that a App sends to your browser while you are viewing a App. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your browser until you delete them) to improve your experience on the App (e.g., by keeping you logged in). Many consider the use of cookies to be industry standard. Persistent Cookies can be removed by following your Internet browser help file directions. If you choose to disable Cookies, you may experience session errors and may need to log-in again for each page you visit on the App.

Disclosure – Usage of Third Party Tracking We may use a third party tracking service to track and analyze Anonymous Information from Users of our App. Such third parties may use Cookies, Android ID, UDID, email address and/or IDFA to help track User behavior.

Our Relationship with Social Networking Sites

We have created and may offer You interfaces that allow You to connect with social networking sites such as Google or Facebook (“SN Site”) through our App. We will work with the SN Site’s application protocol interface (better known as their developer API) in a way that allows You to authorize us to access Your account on that SN Sites on Your behalf. In order to provide this authorization, You will not provide us with Your user name or password to the SN Site, but You will need to log-in to that SN Site directly through our App. Once authorized by You, the SN Site will provide us a token that allows the SN Site to recognize us when we ask, on Your behalf, for access to Your account information or to post information. You will be able to revoke our access to any SN Site at any time by amending the appropriate settings from within Your account settings on the Applicable SN Site, though such revocation may limit the Services we are able to provide You.

USE OF INFORMATION
‍
Personal and Profile Information
We will use your Personal Information to:
• Administer your Account;
• Provide you and other Members with Services and customer support;
• Target content to You or on Your behalf
• Respond to your requests, resolve disputes and/or troubleshoot problems;
• Verify your compliance with your obligations in our Terms of Use or other WishWell policies;
• Improve the quality of the App and the Services; and
• Communicate with you about the App and the Services. We may also use your Personal Information to create Anonymous Information records by excluding information that makes the information personally identifiable to you.

We may send you communications via email about the App and Services. You can opt out of receiving certain communications from the App by clicking the “Unsubscribe” link at the bottom of each email or by emailing us at support@wishwell.to. Please note that even if you unsubscribe or opt-out, we may still send you App and Service related communications (e.g., emails related to your Account).

Anonymous Information
We may, through our use of Cookies and web bugs, keep you logged into our App, track your session history, collect your click-stream data, and personalize your experience on the App. We may use your Anonymous Information to analyze usage patterns so that we may enhance the App or Services, improve our internal operations and the content of our software, notify visitors about updates to our App or Services, and improve the efficacy of our content and advertisements that appear on the App. We may also use your Anonymous Information (including Cookies) to market our products and services to you, whether on our App or on third party Apps.

DISCLOSURE OF INFORMATION
‍
Personal Information
We may disclose certain Personal Information:
• To third party vendors who help us provide the Services or the App;
• To third parties to whom you ask us to send Personal Information (including other Members);
• To a parent company, any subsidiaries, joint ventures, or other companies under a common control (collectively, “Affiliates”), in the event we have such Affiliates in the future, in which case we will require our Affiliates to honor this Privacy Policy;
• To a company that merges with us, acquires us, or purchases our assets, in which case such company may continue to process your Personal Information as set forth in this Privacy Policy; or
• If we believe in good faith that such disclosure is necessary to (a) resolve disputes, investigate problems, or enforce our Terms of Use; (b) comply with relevant laws or to respond to requests from law enforcement or other government officials relating to investigations or alleged illegal activity, in which case we can (and you authorize us to) disclose Personal Information without subpoenas or warrants served on us; or (c) protect and defend our rights or property or the rights and property of you or third parties.


Anonymous Information
We may disclose Anonymous Information without restriction.

RIGHTS OF CALIFORNIANS

As per The California Consumer Privacy Act of 2018
‍(1) The right of Californians to know what personal information is being collected about them.
(2) The right of Californians to know whether their personal information is sold or disclosed and to whom.
(3) The right of Californians to say no to the sale of personal information.
(4) The right of Californians to access their personal information.
(5) The right of Californians to equal service and price, even if they exercise their privacy rights.

FEEDBACK
‍
If You provide feedback to us, we may use and disclose such feedback for any purpose, provided we do not associate such feedback with Your Personal Information. We will collect any information contained in such feedback and will treat the Personal Information in it in accordance with this Privacy Statement. Accessing And Controlling Your Information.

You can review and/or change any of your Personal Information or Profile Information by logging into the App and accessing your Account. You authorize us to use and disclose any new or modified information that you provide in accordance with this Privacy Policy. Please remember, however, if we have already disclosed some of your Personal Information or Profile Information to third parties, we cannot access that Personal Information or Profile Information any longer and cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures.

Information Disclosed to Third Parties
This Privacy Policy addresses only our use and disclosure of information we collect from and/or about you. If you disclose information to others, including to other Members, the use and disclosure restrictions contained in this Privacy Policy will not apply to any third party. We do not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where Applicable.

The App or Services may contain links to other apps that are not owned or controlled by us. We have no control over, do not review and are not responsible for the privacy policies of or content displayed on such other apps. When you click on such a link, you will leave our App or Services and go to another app. During this process, another entity may collect Personal Information or Anonymous Information from you.

Changes to this Privacy Policy.
This Privacy Policy is subject to revision from time to time on a going-forward basis. We will post any revised version of the Privacy Policy on this page. Continued use of our Services following such changes will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.

Contact Us.
If you have questions or concerns about our Privacy Policy, please contact us by sending an email to: support@wishwell.to

Request Personal Information Collected
To request what personal information of your is collected, please contact us at support@wishwell.to

This Policy was last revised: Jan 10, 2021`
    }
  },
  computed: {
    privacy_linebreak: function() {
      return this.privacy.replace(/(?:\r\n|\r|\n)/g, '<br>')
    }
  },
  components: {
    Header,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.privacy {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: white;
  margin: 20px 0;
}


</style>
